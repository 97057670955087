// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-template-tsx": () => import("./../src/templates/homeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-checklist-template-tsx": () => import("./../src/templates/checklistTemplate.tsx" /* webpackChunkName: "component---src-templates-checklist-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../src/templates/tagTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../src/templates/categoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */)
}

